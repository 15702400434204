import { makeAuthRequest } from '../../../api/RequestHelpers'
import { encodeFilter } from '../../../components/filter/EncodeFilter'
import { checkForDefaultError } from '../../../helpers/errorFunctions'

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS'
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_TRANSACTIONS_FAIL = 'FETCH_TRANSACTIONS_FAIL'

export const fetchTransactions = async ({ authContext, transactionContext, filters }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: FETCH_TRANSACTIONS
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `/transactions/${encodeFilter(filters)}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: FETCH_TRANSACTIONS_SUCCESS,
      payload: response.data
    })
    // console.log(response.data)
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: FETCH_TRANSACTIONS_FAIL,
        payload: checkForDefaultError(e)
      })
      throw e
    } else {
      dispatch({
        type: FETCH_TRANSACTIONS_FAIL,
        payload: e
      })
      throw e
    }
  }
}

export const SET_SELECTED_TRANSACTION = 'SET_SELECTED_TRANSACTION'

export const setTransaction = ({ transactionId, transactionContext }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: SET_SELECTED_TRANSACTION,
    payload: transactionId
  })
}

export const SET_FILTERS = 'SET_FILTERS'

export const setTransactionFilters = ({ filters, transactionContext }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: SET_FILTERS,
    payload: filters
  })
}
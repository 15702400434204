
export const SET_DEPOSIT_FIELD = 'SET_DEPOSIT_FIELD'
export const setDepositField = ({ field, value, depositContext }) => {
  depositContext.dispatch({
    type: SET_DEPOSIT_FIELD,
    payload: {
      field, value
    }
  })
}

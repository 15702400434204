import React from 'react'

import {
  FETCH_VENDORS,
  FETCH_VENDORS_SUCCESS,
  FETCH_TRANSACTION_SUCCESS,
  FINISH_TRANSACTION,
  FINISH_TRANSACTION_FAIL,
  FINISH_TRANSACTION_SUCCESS, SEARCH_WITH_PIN, SEARCH_WITH_PIN_SUCCESS, SEARCH_WITH_PIN_FAIL, REMOVE_PIN_ERROR
} from '../TransactionActions'
import { SET_WITHDRAW_FIELD } from './WithdrawActions'
import { FETCH_META_DATA_SUCCESS } from '../../home/HomeActions'

const initialState = {
  loading: false,
  vendors: [],
  transaction: null,
  currencies: [],
  transactionError: '',
  pinSearchError: '',
  withdrawFields: {
    selectedCurrency: null,
    amount: null,
    image: null,
    amlValidation: false,
    exhangedAmount: null,
    customerCurrency: null
  },
  successfulTransaction: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case FINISH_TRANSACTION:
      return { ...state, loadingSendTransaction: true, transactionError: '', successfulTransaction: null }
    case FINISH_TRANSACTION_FAIL:
      return { ...state, loadingSendTransaction: false, transactionError: action.payload }
    case FINISH_TRANSACTION_SUCCESS:
      return { ...state, loadingSendTransaction: false, successfulTransaction: action.payload, transactionError: '' }
    case SET_WITHDRAW_FIELD:
      return { ...state, withdrawFields: { ...state.withdrawFields, [action.payload.field]: action.payload.value } }

    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        withdrawFields: {
          ...initialState.withdrawFields,
          amount: action.payload.remainingAmount || action.payload.amount,
          customerCurrency: { value: action.payload.customer.currency, label: action.payload.customer.currency },
          selectedCurrency: { value: action.payload.customer.currency, label: action.payload.customer.currency }
        }

      }
    case FETCH_META_DATA_SUCCESS:
      if (action.payload.currencies && action.payload.currencies.length === 1) {
        return {
          ...state,
          currencies: action.payload.currencies,
          withdrawFields: {
            ...state.withdrawFields,
            selectedCurrency: { label: action.payload.currencies[0], value: action.payload.currencies[0] }
          }
        }
      } else {
        return {
          ...state,
          currencies: action.payload.currencies
        }
      }
    case FETCH_VENDORS:
      return {
        loading: true
      }
    case FETCH_VENDORS_SUCCESS:
      return {
        loading: false,
        vendors: action.payload.companies
      }
    case SEARCH_WITH_PIN: {
      return {
        ...state,
        loading: true
      }
    }
    case SEARCH_WITH_PIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        pinSearchError: '',
        withdrawFields: {
          ...initialState.withdrawFields
        },
        loading: false
      }
    }
    case SEARCH_WITH_PIN_FAIL: {
      return {
        ...state,
        loading: false,
        pinSearchError: action.payload
      }
    }
    case REMOVE_PIN_ERROR: {
      return {...state, pinSearchError: ''}
    }
    default:
      return state
  }
}

const WithdrawContext = React.createContext()

function WithdrawContextProvider ({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <WithdrawContext.Provider value={{ state, dispatch }}>
      {children}
    </WithdrawContext.Provider>
  )
}

const WithdrawContextConsumer = WithdrawContext.Consumer

export { WithdrawContext, WithdrawContextConsumer, WithdrawContextProvider }

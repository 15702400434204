import axios from 'axios'
import { clientConfig } from '../api/config'
import { checkForDefaultError } from '../helpers/errorFunctions'
import { makeAuthRequest } from '../api/RequestHelpers'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS'
export const CHANGE_LANGUAGE_FAIL = 'CHANGE_LANGUAGE_FAIL'

export const changeLanguage = async ({ imei, translationsContext, language, authContext }) => {
  let changeLanguageResponse
  const { dispatch } = translationsContext
  dispatch({
    type: CHANGE_LANGUAGE
  })
  try {
    changeLanguageResponse = await makeAuthRequest({
      method: 'put',
      url: `terminals/${imei}/change-language`,
      data: {
        language,
        imei
      }
    })({ authContext })
    localStorage.setItem('language', language)
    dispatch({
      type: CHANGE_LANGUAGE_SUCCESS,
      payload: language
    })
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: CHANGE_LANGUAGE_FAIL,
        payload: checkForDefaultError(e)
      })
    } else {
      dispatch({
        type: CHANGE_LANGUAGE_FAIL,
        payload: e
      })
    }
  }
}

import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, redirectTo = '/auth/login', render, allowed = true, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (allowed) {
        if (render) {
          return render(props)
        } else {
          return <Component {...props} />
        }
      } else {
        return (
          <Redirect to={{
            pathname: redirectTo,
            state: { from: props.location }
          }}
          />)
      }
    }}
  />

)

import React from 'react'
import styled from 'styled-components'

const LoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 30px;
  height: 100%;
  display: flex;
& div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  align-self: center;
  border: 1px solid ${({ color }) => color || '#0492F2'};
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ color }) => color || '#0492F2'} transparent transparent transparent;
}
& div:nth-child(1) {
  animation-delay: -0.45s;
}
& div:nth-child(2) {
  animation-delay: -0.3s;
}
& div:nth-child(3) {
  animation-delay: -0.15s;
}

`

export default function Loader ({ color }) {
  return (
    <LoaderWrapper color={color}><div /><div /><div /><div /></LoaderWrapper>
  )
}

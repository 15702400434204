import React from 'react'

import EN from './en.json'
import FR from './fr.json'
import PT from './pt.json'
import RO from './ro.json'
import { CHANGE_LANGUAGE_SUCCESS } from './TranslationsActions'

const translations = {
  en: EN,
  ro: RO,
  pt: PT,
  fr: FR
}

// This function will be used to create `translate` function for the context
const getTranslate = langCode => (key, options) => {
  const translation = translations[langCode][key] || key
  if (options) {
    if (options.firstUpperCase) {
      return translation.substr(0, 1).toUpperCase() + translation.substr(1)
    }
    if (options.uppercase) {
      return translation.toUpperCase()
    }
  }
  return translation
}

const initialState = {
  language: 'ro',
  t: getTranslate('ro')
}

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        language: action.payload.toLowerCase(),
        t: getTranslate(action.payload.toLowerCase())
      }
    default:
      return state
  }
}
const languagePersistor = (reducer) => (state, action) => {
  const newState = reducer(state, action)
  if (action.type === CHANGE_LANGUAGE_SUCCESS) {
    localStorage.setItem('language', JSON.stringify(action.payload.toLowerCase()))
  }
  return newState
}
const getInitialStateWithLanguage = (initialState = {}) => {
  const language = JSON.parse(localStorage.getItem('language'))
  let state = {}
  if (language) {
    state = {
      language,
      t: getTranslate(language)
    }
  } else {
    state = initialState
  }
  return state
}

const TranslationsContext = React.createContext()

function TranslationsContextProvider ({ children }) {
  const [state, dispatch] = React.useReducer(languagePersistor(reducer), getInitialStateWithLanguage(initialState))
  return (
    <TranslationsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TranslationsContext.Provider>
  )
}

const TranslationsContextConsumer = TranslationsContext.Consumer

export { TranslationsContext, TranslationsContextConsumer, TranslationsContextProvider }

import styled from 'styled-components'
import {
  colorBalanceBg, colorFail,
  colorLightGrey,
  colorPrimary, colorTextDark, colorTextLight,
  colorWhite,
  fontSizeLarge, fontSizeNormal,
  fontSizeSmall,
  fontSizeSmaller,
  headerPadding, longTransition, simpleTransition
} from '../../styles/constants'
import logo from '../../assets/images/logo_toppay.png'

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  background: ${colorWhite};
  border-bottom: 1px solid #E1EEF6;
`

export const HeaderContent = styled.div`
  padding: ${headerPadding};
  display: flex;
  box-sizing:border-box;
  //justify-content: space-between;
  align-items: center;
  & > * {
    align-self: center;
  }
  ${({ web }) => web && `width: 60%;

  align-self: center;
  padding: 0;
  @media only screen and (max-width: 1200px) {
    width:80%;
  }
  @media only screen and (max-width: 900px) {
    width:100%;
    padding-left:20px;
    padding-right:20px;

  }
  `}
`

export const Logo = styled.div`
  width: 75px;
  height: 35px;
  background: url('${logo}');
  background-size: contain;
  background-repeat: no-repeat;
  ${({ web }) => web && `
    width: 110px;
    height: 50px;
    margin: 5px;
  `}
`

export const UserWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1vw;
  ${({ web }) => web && 'cursor: initial;'}
`

export const UserLabel = styled.p`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: ${fontSizeSmaller};
  color: ${colorLightGrey};
`

export const UserName = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${fontSizeSmall};
  color: ${colorPrimary};
  ${({ web }) => web && `font-size: ${fontSizeNormal};`}
`

export const UserInformation = styled.div`
  display: flex;
  flex-flow: column;
`

export const DropdownWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`

export const DropdownIcon = styled.div`
  display: flex;
  margin-left: 5px;
  align-self: flex-end;
  height: fit-content;
  justify-content: center;
  width: fit-content;
  & i {
    transition: ${simpleTransition};
    font-size: ${fontSizeLarge};
    margin: 0;
    padding: 0;
    color: ${colorPrimary};
    ${({ rotate }) => rotate && 'transform: rotate(-180deg);'}
  }
`

export const DropdownContent = styled.div`
  overflow: hidden;
  transition: ${simpleTransition};
  padding: 0 30px;
  max-height: 0;
  ${({ visible }) => visible && `
    overflow: visible;
    max-height: 200px;   
    padding: 30px;
    padding-bottom: 15px;
    padding-top: 0;`
}
  ${({ web }) => web && `
    display: flex;
    width: 100%;
    margin-bottom:42px;
    overflow: initial;
    & > * {
      flex: 1;
    }
  `}
`

export const DropdownTitle = styled.h2`
  margin: 0;
  padding: 0;
  color: ${colorTextDark};
  font-size: ${fontSizeLarge};
`

export const DropdownDetails = styled.p`
display:flex;
  padding: 0;
  margin: 0;
  max-width: 290px;
  color: ${colorTextLight};
  font-size: ${fontSizeSmall};
  ${({ web }) => web && `
    margin: 0;
    max-width: fit-content;
    font-size: ${fontSizeNormal};
  `}
  ${({ noMaxWidth }) => noMaxWidth && `
    max-width: unset;
  `}
`

export const BalanceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 20px;
  flex:1;
  ${({ web }) => web && 'margin: 0;'}
`

export const BalanceTitle = styled(DropdownTitle)`
  font-size: ${fontSizeNormal};
  ${({ web }) => web && `font-size: ${fontSizeLarge};`}
`

export const BalanceProgress = styled.div`
  height: 28px;
  margin-top: 13px;
  width: 100%;
  display: flex;
  background: ${colorBalanceBg};
  border-radius: 2px;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
    width: auto;
    height: 100%;
    content: '';
    display: flex;
    transition: ${longTransition};
    width: 0;
    background: ${colorTextDark};  
    ${({ progress }) => progress && progress < 0 ? `width: ${-progress}%; background: ${colorFail}; border-right: 2px solid ${colorTextDark};` : `width: ${progress}%;`}
  }
  ${({ web }) => web && 'height: 17px;'}
`
export const BalanceValue = styled.p`
  display: flex;
  justify-content: flex-end;
  padding-right: 2px;
  position: absolute;
  bottom: 100%;
  margin: 0;
  padding: 0;
  color: ${colorTextDark};
  font-size: ${fontSizeSmaller};
  ${({ progress }) => progress && progress < 0 ? `width: ${-progress}%;` : `width: ${progress}%;`}

`

export const BalanceCounter = styled.div`
  display: flex;
  width: 100%;
  top: 100%;
  justify-content: space-between;
  position: absolute;
`

export const BalanceLimit = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  font-size: ${fontSizeSmaller};
  color: ${colorTextDark};
  ${({ progress }) => progress && `width: ${-progress}%;`}
`

export const DisconnectButton = styled.p`
  cursor: pointer;
  margin: 0;
  display: flex;
  align-self: center;
  padding: 0;
  font-size: ${fontSizeSmaller};
  color: ${colorTextLight};
  & i {
    margin-left: 5px;
    align-self: center;
    font-size: ${fontSizeSmall};
  }
`
export const DisconnectButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  ${({ web }) => web && `
    margin: 0;
    justify-content: flex-end;
  `}
`

import React, { useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'

import { logout } from '../../modules/auth/AuthActions'
import { AuthContext } from '../../modules/auth/AuthContext'
import { HomeContext } from '../../modules/home/HomeProvider'
import { changeLanguage } from '../../translations/TranslationsActions'
import { TranslationsContext } from '../../translations/TranslationsProvider'
import Loader from '../Loader'
import {
  DisconnectButton,
  DisconnectButtonWrapper,
  HeaderContent,
  HeaderWrapper,
  Logo,
  UserInformation,
  UserName,
  UserWrapper,
} from './styles'
import LanguageChanger from "../../translations/LanguageChanger";

function HeaderWeb ({ history }) {
  const authContext = useContext(AuthContext)
  const homeContext = useContext(HomeContext)
  const { user } = homeContext.state
  const { terminal } = homeContext.state
  const translationsContext = useContext(TranslationsContext)
  const { t } = translationsContext

  const changeTerminalLanguage = (language) => {
    changeLanguage({ language, imei: terminal.imei, authContext, translationsContext })
  }
  return (
    <HeaderWrapper>
      <HeaderContent web>
        <Link to='/home'>
          <Logo web />
        </Link>
        <UserWrapper web>
          <UserInformation>
            {user.username ? <UserName web>{user.username}</UserName> : (
              <div style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
              </div>)}
            <DisconnectButtonWrapper web>
              <DisconnectButton onClick={() => logout({ authContext, history })}>
                {t('disconnect', { firstUpperCase: true })}
                <i className='material-icons'>exit_to_app</i>
              </DisconnectButton>
            </DisconnectButtonWrapper>
          </UserInformation>
        </UserWrapper>
        <LanguageChanger />
      </HeaderContent>
    </HeaderWrapper>
  )
}

export default withRouter(HeaderWeb)

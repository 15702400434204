import axios from 'axios'
import { refreshToken } from '../modules/auth/AuthActions'
import moment from 'moment-timezone'
import showErrorAlert from '../alerts/errorAlert'

export const makeAuthRequest = (data) => async ({ authContext, showErrors = false, history }) => {
  let myData = { ...data }
  let refreshedToken
  if (authContext.state.refreshing) {
    console.log('an token is beeing refreshed by another call')
    refreshedToken = await authContext.state.refreshing
    console.log('token has been refreshed by another call:', refreshedToken)
    if (refreshedToken && refreshedToken.data && refreshedToken.data.token_type) {
      myData = { ...myData, headers: { ...myData.headers, Authorization: `${refreshedToken.data.token_type} ${refreshedToken.data.access_token}` } }
      console.log('continuing with myData:', myData)
    }
    return axios(myData)
  }
  try {
    const rawToken = await localStorage.getItem('token')
    const tokenData = JSON.parse(rawToken)
    if (tokenData.access_token) {
      const tokenExpired = localStorage.getItem('tokenExpired')
      // if ((tokenData.refresh_token && ((tokenData.expires_in * 1000 - moment().valueOf()) < 1000)) || tokenExpired === 'true') {
      if (tokenExpired === 'true' || (moment().valueOf() > tokenData.expires_in)) {
        console.log('token expired: ', tokenData)

        try {
          refreshedToken = await refreshToken(authContext)
        } catch (e) {
          console.log('failed refreshing token in this call, returning')
          return
        }
        
        console.log('token refreshed in this call : ', refreshedToken)
        if (refreshedToken) {
          myData = { ...myData, headers: { ...myData.headers, Authorization: `${refreshedToken.token_type} ${refreshedToken.access_token}` } }
          console.log('calling with refreshed token with myData : ', myData)
        }
      } else {
        console.log('token is not expired, attaching stored token:', tokenData)
        myData = { ...myData, headers: { ...myData.headers, Authorization: `${tokenData.token_type} ${tokenData.access_token}` } }
      }
    } else {
      console.log('there is no token available')
      if (history) {
        history.push('/auth/login')
      }
    }
    return axios(myData)
  } catch (error) {
    if (error.response) {
      if (showErrors && error.response.data) {
        if (error.response.data.errors) {
          error.response.data.errors.map(error => showErrorAlert(error))
        }
      } else {
        throw error
      }
    }
  }
}

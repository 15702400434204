
export const SET_WITHDRAW_FIELD = 'SET_WITHDRAW_FIELD'
export const setWithdrawField = ({ field, value, withdrawContext }) => {
  withdrawContext.dispatch({
    type: SET_WITHDRAW_FIELD,
    payload: {
      field, value
    }
  })
}

import React from 'react'
import {
  FETCH_USER_SUCCESS, FETCH_META_DATA_SUCCESS, SET_META_DATA_INTERVAL, FETCH_MERCHANT_DETAILS_SUCCESS
} from './HomeActions'
import { LOGOUT_SUCCESS } from '../auth/AuthActions'

const initialState = {
  company: {},
  permissions: {},
  role: {},
  terminal: {},
  user: {},
  languages: {},
  currencies: [],
  metaDataInterval: null,
  address: null,
  companyName: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return { ...state, ...action.payload }
    case FETCH_META_DATA_SUCCESS:
      return { ...state, ...action.payload }
    case SET_META_DATA_INTERVAL:
      return { ...state, metaDataInterval: action.payload }
    case FETCH_MERCHANT_DETAILS_SUCCESS:
      return { ...state, ...action.payload }
    case LOGOUT_SUCCESS:
      return { ...initialState }
    default:
      return state
  }
}

const HomeContext = React.createContext()
function HomeContextProvider (props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <HomeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </HomeContext.Provider>
  )
}

const HomeContextConsumer = HomeContext.Consumer

export { HomeContext, HomeContextProvider, HomeContextConsumer }

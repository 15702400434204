import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import React, { Suspense, lazy, useContext } from 'react'
import { PrivateRoute } from './PrivateRoute'
import { BrowserView, MobileView } from 'react-device-detect'
import HeaderMobile from '../components/header/HeaderMobile'
import HeaderWeb from '../components/header/HeaderWeb'
import { AuthContext } from '../modules/auth/AuthContext'
import { WithdrawContextProvider } from '../modules/transactions/withdraw/WithdrawProvider'
import { DepositContextProvider } from '../modules/transactions/deposit/DepositProvider'
import { HomeContextProvider } from '../modules/home/HomeProvider'
import { TransactionContextProvider } from '../modules/transactions/TransactionProvider'
import { HistoryContextProvider } from '../modules/transactions/history/HistoryProvider'
export default function Router() {
  const authContext = useContext(AuthContext)
  const { loggedIn } = authContext.state
  return (
    <BrowserRouter>
      {loggedIn && (
        <DepositContextProvider>
          <WithdrawContextProvider>
            <HistoryContextProvider>
              <HomeContextProvider>
                <TransactionContextProvider>
                  <BrowserView>
                    <HeaderWeb />
                    <RouterContent loggedIn={loggedIn} />
                  </BrowserView>
                  <MobileView>
                    <HeaderMobile />
                    <RouterContent loggedIn={loggedIn} />
                  </MobileView>
                </TransactionContextProvider>

              </HomeContextProvider>
            </HistoryContextProvider>
          </WithdrawContextProvider>
        </DepositContextProvider>
      )}
      {!loggedIn && (
        <>
          <BrowserView>
            <RouterContent loggedIn={loggedIn} />
          </BrowserView>
          <MobileView>
            <RouterContent loggedIn={loggedIn} />
          </MobileView>
        </>
      )}
    </BrowserRouter>
  )
}

export function RouterContent({ loggedIn }) {
  return (
    <Suspense fallback={<div />}>
      {!loggedIn &&
        <Route
          path='/auth'
          component={lazy(() => import('../modules/auth/AuthRouter'))}
        />}
      <PrivateRoute
        path='/transactions'
        allowed={loggedIn}
        component={lazy(() => import('../modules/transactions/TransactionsRouter'))}
      />
      <PrivateRoute
        path='/home'
        allowed={loggedIn}
        component={lazy(() => import('../modules/home/HomeRouter'))}
      />
      <Route path='' render={() => <Redirect to={loggedIn ? '/home' : '/auth/login'} />} />
    </Suspense>
  )
}

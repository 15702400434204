import _ from 'lodash'

export const encodeFilter = ({
    filterBy,
    orderBy,
    searchBy,
    currentIndex,
    pagination,
},
) => {
    const {
        date,
        locationId
    } = filterBy

    let index = currentIndex || 0

    let encodedFilter
    encodedFilter = '?pageCount=10'

    if (orderBy && orderBy.value) {
        encodedFilter += `&orderBy[${index}][0]=${orderBy.name}&orderBy[${index}][1]=${orderBy.value}`
        index++
    }

    if (pagination && pagination.pageSize) {
        encodedFilter += `&pageSize=${pagination.pageSize.value}`
    }

    if (pagination && pagination.page && pagination.page.value) {
        encodedFilter += `&page=${pagination.page.value}`
    }

    if (date) {
        encodedFilter += `&filterBy[${index}][0]=${date[0].name}&filterBy[${index}][1]=${date[0].operator}&filterBy[${index}][2]=${date[0].value}`
        index++
        encodedFilter += `&filterBy[${index}][0]=${date[1].name}&filterBy[${index}][1]=${date[1].operator}&filterBy[${index}][2]=${date[1].value}`
        index++
    }

    if (locationId) {
        encodedFilter += `&filterBy[${index}][0]=${locationId.name}&filterBy[${index}][1]=${locationId.operator}&filterBy[${index}][2]=${locationId.value}`
        index++
    }

    return encodedFilter
}
import React from 'react'
import {
    FETCH_TRANSACTIONS,
    FETCH_TRANSACTIONS_SUCCESS,
    FETCH_TRANSACTIONS_FAIL,
    SET_SELECTED_TRANSACTION,
    SET_FILTERS
} from '../history/HistoryActions'

const initialState = {
    loading: false,
    transactions: [],
    pagination: {},
    error: {},
    selectedTransaction: {},
    transactionFilters: {}
}

const reducer = (state, action) => {
    switch (action.type) {
        case FETCH_TRANSACTIONS:
            return { ...state, loading: true }
        case FETCH_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                transactions: action.payload.transactions,
                pagination: action.payload.pagination
            }
        case FETCH_TRANSACTIONS_FAIL:
            return { ...state, loading: false, error: action.payload }

        case SET_SELECTED_TRANSACTION:
            return { ...state, loading: true, selectedTransaction: action.payload }

        case SET_FILTERS:
            return { ...state, loading: true, transactionFilters: action.payload }

        default:
            return state
    }
}

const HistoryContext = React.createContext()

function HistoryContextProvider({ children }) {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    return (
        <HistoryContext.Provider value={{ state, dispatch }}>
            {children}
        </HistoryContext.Provider>
    )
}

const HistoryContextConsumer = HistoryContext.Consumer

export { HistoryContext, HistoryContextProvider, HistoryContextConsumer }

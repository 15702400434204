import { makeAuthRequest } from '../../api/RequestHelpers'
import { checkForDefaultError } from '../../helpers/errorFunctions'
import _ from 'lodash'

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER'
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL'

export const fetchCustomer = async ({ authContext, transactionContext, customerId }) => {
  const { dispatch } = transactionContext
  let response
  dispatch({
    type: FETCH_CUSTOMER
  })
  try {
    response = await makeAuthRequest({
      url: `/customers/${customerId}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: FETCH_CUSTOMER_SUCCESS,
      payload: response.data
    })
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: FETCH_CUSTOMER_FAIL,
        payload: checkForDefaultError(e)
      })
      throw e
    } else {
      throw e
    }
  }
}
export const FETCH_COMPANY = 'FETCH_COMPANY'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANYY_SUCCESS'
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL'
export const fetchCompany = async ({ authContext, transactionContext, companyId, type = 'vendor' }) => {
  const { dispatch } = transactionContext
  let response
  dispatch({
    type: FETCH_COMPANY
  })
  try {
    response = await makeAuthRequest({
      url: `/companies/${companyId}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: FETCH_COMPANY_SUCCESS,
      payload: response.data,
      companyType: 'vendor'
    })
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: FETCH_COMPANY_FAIL,
        payload: checkForDefaultError(e)
      })
      throw e
    } else {
      throw e
    }
  }
}

export const FETCH_VENDORS = 'FETCH_VENDORS'
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS'
export const FETCH_VENDORS_FAIL = 'FETCH_VENDORS_FAIL'

export const fetchVendors = async ({ authContext, depositContext, imei }) => {
  localStorage.setItem('tokenExpired', false)
  const { dispatch } = depositContext
  dispatch({
    type: FETCH_VENDORS
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `/terminals/${imei}/available-vendors`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: FETCH_VENDORS_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    dispatch({ type: FETCH_VENDORS_FAIL, payload: e })
    if (_.get(e, 'response.data.error_type') === 'expired_token_error' ||
      _.get(e, 'response.data.error_type') === 'authentication_error'
    ) {
      localStorage.setItem('tokenExpired', true)
    }
  }
}

export const FETCH_TRANSACTION = 'FETCH_TRANSACTION'
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS'
export const FETCH_TRANSACTION_FAIL = 'FETCH_TRANSACTION_FAIL'

export const fetchTransaction = async ({ authContext, transactionContext, transactionId }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: FETCH_TRANSACTION
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `/transactions/${transactionId}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: FETCH_TRANSACTION_SUCCESS,
      payload: response.data
    })
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: FETCH_TRANSACTION_FAIL,
        payload: checkForDefaultError(e)
      })
      throw e
    } else {
      dispatch({
        type: FETCH_TRANSACTION_FAIL,
        payload: e
      })
      throw e
    }
  }
}

export const SEARCH_FOR_CUSTOMER_AT_VENDOR = 'SEARCH_FOR_CUSTOMER_AT_VENDOR'
export const SEARCH_FOR_CUSTOMER_AT_VENDOR_SUCCESS = 'SEARCH_FOR_CUSTOMER_AT_VENDOR_SUCCESS'
export const SEARCH_FOR_CUSTOMER_AT_VENDOR_FAIL = 'SEARCH_FOR_CUSTOMER_AT_VENDOR_FAIL'
export const searchForCustomerAtVendor = async ({ authContext, transactionContext, companyId, username }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: SEARCH_FOR_CUSTOMER_AT_VENDOR
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `/companies/${companyId}/customer?username=${username}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: SEARCH_FOR_CUSTOMER_AT_VENDOR_SUCCESS,
      payload: response.data
    })
    return response.data
  } catch (e) {
    console.log(e.response.data)
    if (checkForDefaultError(e)) {
      dispatch({
        type: SEARCH_FOR_CUSTOMER_AT_VENDOR_FAIL,
        payload: checkForDefaultError(e)
      })
      throw checkForDefaultError(e)
    } else {
      dispatch({
        type: SEARCH_FOR_CUSTOMER_AT_VENDOR_FAIL,
        payload: e
      })
      throw e
    }
  }
}


export const SEARCH_WITH_PIN = 'search_with_pin'
export const SEARCH_WITH_PIN_SUCCESS = 'search_with_pin_success'
export const SEARCH_WITH_PIN_FAIL = 'search_with_pin_fail'
export const searchForTransactionWithPin = async ({ authContext, transactionContext, username}) => {
  const { dispatch } = transactionContext
  dispatch({
    type: SEARCH_WITH_PIN
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `/transactions/pin/${username}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: SEARCH_WITH_PIN_SUCCESS,
      payload: response.data
    })
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: SEARCH_WITH_PIN_FAIL,
        payload: checkForDefaultError(e)
      })
      throw checkForDefaultError(e)
    }
  }
}

export const REMOVE_PIN_ERROR = 'remove_pin_error'
export const removePinError = async (transactionContext) => {
    const { dispatch } = transactionContext
  dispatch({
    type: REMOVE_PIN_ERROR
  })
}

export const CHECK_FOR_AML_VALIDATION = 'CHECK_FOR_AML_VALIDATION'
export const CHECK_FOR_AML_VALIDATION_SUCCESS = 'CHECK_FOR_AML_VALIDATION_SUCCESS'
export const CHECK_FOR_AML_VALIDATION_FAIL = 'CHECK_FOR_AML_VALIDATION_FAIL'
export const checkForAmlValidation = async ({ authContext, transactionContext, customerId, vendorId, amount, currency }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: CHECK_FOR_AML_VALIDATION
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `companies/${vendorId}/validate-limits?customerId=${customerId}&amount=${amount}&currency=${currency}`,
      method: 'get'
    })({ authContext })
    dispatch({
      type: CHECK_FOR_AML_VALIDATION_SUCCESS,
      payload: response.data
    })
    return response.data
  } catch (e) {
    console.log(e.response.data)
    if (checkForDefaultError(e)) {
      dispatch({
        type: CHECK_FOR_AML_VALIDATION_FAIL,
        payload: checkForDefaultError(e)
      })
    } else {
      dispatch({
        type: CHECK_FOR_AML_VALIDATION_FAIL,
        payload: e
      })
    }
  }
}

export const exchangeCurrency = async ({ fromCurrency, toCurrency, amount, transactionType, authContext, vendorId }) => {
  let response
  try {
    response = await makeAuthRequest({
      url: `transactions/exchange-currency?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}&amount=${amount}&transactionType=${transactionType}&vendorId=${vendorId}`,
      method: 'get'
    })({ authContext })
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      return checkForDefaultError(e)
    } else {
      return e
    }
  }
}

export const FINISH_TRANSACTION = 'FINISH_TRANSACTION'
export const FINISH_TRANSACTION_SUCCESS = 'FINISH_TRANSACTION_SUCCESS'
export const FINISH_TRANSACTION_FAIL = 'FINISH_TRANSACTION_FAIL'
export const finishTransaction = async ({ authContext, transactionContext, data, type, history, t }) => {
  console.log('bbbbbbbb', data)

  const { dispatch } = transactionContext
  dispatch({
    type: FINISH_TRANSACTION
  })
  let response
  try {
    response = await makeAuthRequest({
      url: 'transactions',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })({ authContext })
    dispatch({
      type: FINISH_TRANSACTION_SUCCESS,
      payload: response.data
    })
    history.push(`/transactions/${type}/success`)
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: FINISH_TRANSACTION_FAIL,
        payload: checkForDefaultError(e)
      })
    } else {
      dispatch({
        type: FINISH_TRANSACTION_FAIL,
        payload: t('unexpectedError', { firstUpperCase: true })
      })
    }
    history.push(`/transactions/${type}/fail`)
  }
}

export const CLOSE_TRANSACTIONAL_DAY = 'CLOSE_TRANSACTIONAL_DAY'
export const CLOSE_TRANSACTIONAL_DAY_SUCCESS = 'CLOSE_TRANSACTIONAL_DAY_SUCCESS'
export const CLOSE_TRANSACTIONAL_DAY_FAIL = 'CLOSE_TRANSACTIONAL_DAY_FAIL'

export const closeTransactionalDay = async ({ authContext, transactionContext, data, history }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: CLOSE_TRANSACTIONAL_DAY
  })
  let response
  try {
    response = await makeAuthRequest({
      url: 'transactions/close-transactional-day',
      method: 'post',
      data: {
        ...data,
        type: 'closeDay'
      }
    })({ authContext })
    dispatch({
      type: CLOSE_TRANSACTIONAL_DAY_SUCCESS,
      payload: response.data
    })
    history.push('/home/daily-reports/success')
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: CLOSE_TRANSACTIONAL_DAY_FAIL,
        payload: checkForDefaultError(e)
      })
    } else {
      dispatch({
        type: CLOSE_TRANSACTIONAL_DAY_FAIL,
        payload: e
      })
    }
    history.push('/home/daily-reports/fail')
  }
}
export const FETCH_INTERMEDIARY_REPORT = 'FETCH_INTERMEDIARY_REPORT'
export const FETCH_INTERMEDIARY_REPORT_SUCCESS = 'FETCH_INTERMEDIARY_REPORT_SUCCESS'
export const FETCH_INTERMEDIARY_REPORT_FAIL = 'FETCH_INTERMEDIARY_REPORT_FAIL'

export const fetchIntermediaryReport = async ({ authContext, transactionContext, data, history }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: FETCH_INTERMEDIARY_REPORT
  })
  let response
  try {
    response = await makeAuthRequest({
      url: 'transactions/close-transactional-day',
      method: 'post',
      data: {
        ...data, type: 'report'
      }
    })({ authContext })
    dispatch({
      type: FETCH_INTERMEDIARY_REPORT_SUCCESS,
      payload: response.data
    })
    history.push('/home/intermediary/success')
    return response.data
  } catch (e) {
    if (checkForDefaultError(e)) {
      dispatch({
        type: FETCH_INTERMEDIARY_REPORT_FAIL,
        payload: checkForDefaultError(e)
      })
    } else {
      dispatch({
        type: FETCH_INTERMEDIARY_REPORT_FAIL,
        payload: e
      })
    }
    history.push('/home/intermediary/fail')
  }
}

export const SEARCH_FOR_RECEIPT_TO_REPRINT = 'SEARCH_FOR_RECEIPT_TO_REPRINT'
export const SEARCH_FOR_RECEIPT_TO_REPRINT_SUCCESS = 'SEARCH_FOR_RECEIPT_TO_REPRINT_SUCCESS'
export const SEARCH_FOR_RECEIPT_TO_REPRINT_FAIL = 'SEARCH_FOR_RECEIPT_TO_REPRINT_FAIL'

export const searchForReceiptToReprint = async ({ authContext, transactionContext, history, series, number, terminalImei, changeRoute }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: SEARCH_FOR_RECEIPT_TO_REPRINT
  })
  let response
  try {
    response = await makeAuthRequest({
      url: `/transactions/${series}/${number}/receipt-template?imei=${terminalImei}`,
      method: 'get'

    })({ authContext })
    dispatch({
      type: SEARCH_FOR_RECEIPT_TO_REPRINT_SUCCESS,
      payload: response.data
    })
    if (changeRoute !== false) {
      history.push('/transactions/reprint/success')
    }
    return response.data
  } catch (e) {
    console.log(e.response.data)
    if (checkForDefaultError(e)) {
      dispatch({
        type: SEARCH_FOR_RECEIPT_TO_REPRINT_FAIL,
        payload: checkForDefaultError(e)
      })
      throw checkForDefaultError(e)
    } else {
      dispatch({
        type: SEARCH_FOR_RECEIPT_TO_REPRINT_FAIL,
        payload: e
      })
      throw e
    }
  }
}

export const RESET_PRINT_ERROR = 'RESET_PRINT_ERROR'

export const resetPrintError = ({ transactionContext }) => {
  const { dispatch } = transactionContext
  dispatch({
    type: RESET_PRINT_ERROR
  })
}

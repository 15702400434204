import React from 'react'

import {
  FINISH_TRANSACTION_FAIL,
  CLOSE_TRANSACTIONAL_DAY,
  CLOSE_TRANSACTIONAL_DAY_FAIL,
  CLOSE_TRANSACTIONAL_DAY_SUCCESS,
  FETCH_INTERMEDIARY_REPORT_SUCCESS,
  SEARCH_FOR_RECEIPT_TO_REPRINT_SUCCESS,
  SEARCH_FOR_RECEIPT_TO_REPRINT,
  SEARCH_FOR_RECEIPT_TO_REPRINT_FAIL,
  FETCH_INTERMEDIARY_REPORT_FAIL,
  RESET_PRINT_ERROR
} from './TransactionActions'

const initialState = {
  transactionError: '',
  intermediaryReportError: '',
  closeDayError: '',
  closeDayTransaction: null,
  errorReprint: '',
  loadingReprint: false,
  transactionToReprint: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case RESET_PRINT_ERROR:
      return {
        ...state,
        errorReprint: ''
      }

    case SEARCH_FOR_RECEIPT_TO_REPRINT:
      return {
        ...state, errorReprint: '', loadingReprint: true, transactionToReprint: null
      }
    case SEARCH_FOR_RECEIPT_TO_REPRINT_FAIL:
      return {
        ...state,
        errorReprint: action.payload,
        loadingReprint: false
      }
    case SEARCH_FOR_RECEIPT_TO_REPRINT_SUCCESS:
      return {
        ...state, transactionToReprint: action.payload, errorReprint: '', loadingReprint: false
      }
    case FETCH_INTERMEDIARY_REPORT_SUCCESS:
      return {
        ...state,
        intermediaryReport: action.payload,
        intermediaryReportError: ''
      }
    case FETCH_INTERMEDIARY_REPORT_FAIL:
      return {
        ...state,
        intermediaryReportError: action.payload
      }
    case CLOSE_TRANSACTIONAL_DAY:
      return { ...state }
    case CLOSE_TRANSACTIONAL_DAY_SUCCESS:
      return { ...state, closeDayTransaction: action.payload }
    case CLOSE_TRANSACTIONAL_DAY_FAIL:
      return { ...state, closeDayError: action.payload }
    case FINISH_TRANSACTION_FAIL:
      return { ...state, transactionError: action.payload }
    default:
      return state
  }
}

const TransactionContext = React.createContext()

function TransactionContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <TransactionContext.Provider value={{ state, dispatch }}>
      {children}
    </TransactionContext.Provider>
  )
}

const TransactionContextConsumer = TransactionContext.Consumer

export { TransactionContext, TransactionContextConsumer, TransactionContextProvider }

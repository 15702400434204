import { makeAuthRequest } from '../../api/RequestHelpers'
import _ from 'lodash'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL'
export const fetchUserAndMetaData = async ({ authContext, homeContext }) => {
  const { dispatch } = homeContext
  localStorage.setItem('tokenExpired', false)
  dispatch({
    type: FETCH_USER
  })
  try {
    const userResponse = await makeAuthRequest({
      url: '/users/user',
      method: 'get'
    })({ authContext })
    await fetchMerchantDetails({ authContext, homeContext, imei: userResponse && userResponse.data && userResponse.data.terminal.imei })
    await fetchMetaData({ authContext, homeContext, imei: userResponse && userResponse.data && userResponse.data.terminal.imei })
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: userResponse.data
    })
  } catch (e) {
    // console.log('FETCH_USER_FAIL: ', e)
    dispatch({
      type: FETCH_USER_FAIL,
      payload: e
    })
    console.log('vvvvv', _.get(e, 'response.data.error_type') === 'expired_token_error')
    if (_.get(e, 'response.data.error_type') === 'expired_token_error' ||
      _.get(e, 'response.data.error_type') === 'authentication_error'
    ) {
      localStorage.setItem('tokenExpired', true)
    }
    throw e
  }
}

export const FETCH_META_DATA = 'FETCH_META_DATA'
export const FETCH_META_DATA_SUCCESS = 'FETCH_META_DATA_SUCCESS'
export const FETCH_META_DATA_FAIL = 'FETCH_META_DATA_FAIL'
export const SET_META_DATA_INTERVAL = 'SET_META_DATA_INTERVAL'
export const fetchMetaData = async ({ authContext, homeContext, imei }) => {
  const { dispatch } = homeContext
  localStorage.setItem('tokenExpired', false)
  dispatch({
    type: FETCH_META_DATA
  })
  try {
    const response = await makeAuthRequest({
      method: 'get',
      url: `/terminals/${imei}/meta-data`
    })({ authContext })
    dispatch({
      type: FETCH_META_DATA_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    dispatch({
      type: FETCH_META_DATA_FAIL,
      payload: e
    })
    if (_.get(e, 'response.data.error_type') === 'expired_token_error' ||
      _.get(e, 'response.data.error_type') === 'authentication_error'
    ) {
      localStorage.setItem('tokenExpired', true)
    }

    throw e
  }
}

export const FETCH_MERCHANT_DETAILS = 'FETCH_MERCHANT_DETAILS'
export const FETCH_MERCHANT_DETAILS_SUCCESS = 'FETCH_MERCHANT_DETAILS_SUCCESS'
export const FETCH_MERCHANT_DETAILS_FAIL = 'FETCH_MERCHANT_DETAILS_FAIL'
export const fetchMerchantDetails = async ({ authContext, homeContext, imei }) => {
  const { dispatch } = homeContext
  localStorage.setItem('tokenExpired', false)
  dispatch({
    type: FETCH_MERCHANT_DETAILS
  })
  try {
    const response = await makeAuthRequest({
      method: 'get',
      url: `/terminals/address?imei=${imei}`
    })({ authContext })
    dispatch({
      type: FETCH_MERCHANT_DETAILS_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    console.log('FETCH_MERCHANT_DETAILS_FAIL: ', e)
    dispatch({
      type: FETCH_MERCHANT_DETAILS_FAIL,
      payload: e
    })
    if (_.get(e, 'response.data.error_type') === 'expired_token_error' ||
      _.get(e, 'response.data.error_type') === 'authentication_error'
    ) {
      localStorage.setItem('tokenExpired', true)
    }
    throw e
  }
}

// Font family
export const mainTypeface = 'Rubick, sans-serif'

// Fonts
export const fontSizeBigger = '30px'
export const fontSizeBig = '22px'
export const fontSizeIcon = '20px'
export const fontSizeLarge = '18px'
export const fontSizeNormal = '14px'
export const fontSizeSmall = '12px'
export const fontSizeSmaller = '10px'
export const fontSizeTiny = '8px'

// Line height
export const lineHeight = '1.5'

// Neutral White
export const colorLightGrey = '#BBBECD'
export const colorWhite = '#fff'

// Neutral Black
export const colorBlack02 = 'rgba(0, 0, 0, 0.02)'
export const colorBlack12 = 'rgba(0, 0, 0, 0.12)'
export const colorBlack16 = 'rgba(0, 0, 0, 0.16)'
export const colorBlack40 = 'rgba(0, 0, 0, 0.4)'
export const colorBlack60 = 'rgba(0, 0, 0, 0.6)'
export const colorBlack80 = 'rgba(0, 0, 0, 0.8)'
export const colorBlack90 = 'rgba(0, 0, 0, 0.9)'
export const colorBlack = '#000'

// Colors
export const colorTextLight = '#868DAA'
export const colorTextDark = '#4E5983'
export const colorDisabled = '#E1EEF6'
export const colorPrimary = '#0492F2'
export const colorPrimaryGradient = 'linear-gradient(180deg, #009BF5, #0184E1)'
export const colorPrimaryGradientDarker = 'linear-gradient(180deg, #0184E1, #0184E1)'
export const colorSuccess = '#0492F2'
export const colorFail = '#FF6F79'
export const colorWarn = '#ffc36b'
export const colorDigital = '#6780e6'
export const colorGreen = '#46c751'
export const colorBalanceBg = '#DAE8F3'

export const colorFail80 = 'rgba(248, 100, 107, 0.7)'
export const colorPrimary50 = 'rgba(4, 146, 242, 0.5)'
export const colorPrimary80 = 'rgba(74, 144, 226, 0.7)'

// Spacings
export const spacingX0 = '4px'
export const spacingX1 = '8px'
export const spacingX2 = '16px'
export const spacingX3 = '24px'
export const spacingX4 = '32px'
export const spacingX8 = '64px'

export const spacingO1 = '10px'
export const spacingO2 = '12px'
export const spacingO3 = '14px'
export const spacingO4 = '18px'

// Extras
export const boxShadow = '0 4px 10px 0 rgba(0, 0, 0, 0.08);'
export const radiusX0 = '4px'
export const radiusX1 = '8px'
export const radiusX2 = '16px'
export const radiusX3 = '24px'

// Timing

export const timeBlink = '.1s'
export const timeFast = '.2s'
export const timeMedium = '.3s'
export const timeSlow = '.4s'

export const animationIn = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)'
export const animationOut = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)'
export const animationSlide = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)'

// Window
export const vh = window.innerHeight * 0.01
export const realHeight = `calc(${vh}vh * 100)`

// Header

export const headerPadding = '20px'

// Transitions
export const simpleTransition = '0.2s all ease'
export const longTransition = '0.9s all ease'

// Borders
export const secondaryButtonBorder = `1px solid ${colorDisabled}`
export const boxBorder = `1px solid ${colorDisabled}`

import React, { useState, useContext } from 'react'
import Shimmer from 'react-shimmer-effect'
import { Link, withRouter } from 'react-router-dom'
import {
  DisconnectButton, DisconnectButtonWrapper,
  DropdownContent, DropdownDetails, DropdownIcon,
  DropdownTitle,
  DropdownWrapper,
  HeaderContent,
  HeaderWrapper,
  Logo,
  UserInformation,
  UserLabel,
  UserName,
  UserWrapper,
  BalanceWrapper,
  BalanceTitle,
  BalanceProgress,
  BalanceValue,
  BalanceCounter,
  BalanceLimit
} from './styles'
import LanguageChanger from '../../translations/LanguageChanger'
import { logout } from '../../modules/auth/AuthActions'
import { AuthContext } from '../../modules/auth/AuthContext'
import { HomeContext } from '../../modules/home/HomeProvider'
import Loader from '../Loader'
import { TranslationsContext } from '../../translations/TranslationsProvider'

function HeaderMobile ({ history }) {
  const authContext = useContext(AuthContext)
  const homeContext = useContext(HomeContext)
  const { t } = useContext(TranslationsContext)
  const { user, address, companyName } = homeContext.state
  const [dropDown, setDropDown] = useState(false)
  const limit = 6000
  const current = 4000
  return (
    <HeaderWrapper>
      <HeaderContent>
        <Link to='/home'>
          <Logo />
        </Link>
        <UserWrapper onClick={() => setDropDown(!dropDown)}>
          <UserInformation>
            <UserLabel>
              {t('loggedInAs')}
            </UserLabel>
            {user.username ? <UserName>{user.username}</UserName> : (
              <div style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
              </div>)}
          </UserInformation>
          <DropdownIcon rotate={dropDown ? 1 : 0}>
            <i className='material-icons'>keyboard_arrow_down</i>
          </DropdownIcon>
        </UserWrapper>
        <LanguageChanger />
      </HeaderContent>
      <DropdownWrapper>

        <DropdownContent visible={dropDown}>
          {address && companyName ? (
            <>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {/*<LanguageChanger />*/}

                <div>
                  <DropdownTitle>
                    {companyName}
                  </DropdownTitle>
                  <DropdownDetails>
                    {address}
                  </DropdownDetails>
                </div>

              </div>

              {/* <BalanceWrapper>
                <BalanceTitle>
                  {t('balance', { firstUpperCase: true })}
                </BalanceTitle>
                <BalanceProgress progress={current <= limit && (current / limit) * 100}>
                  <BalanceValue progress={current <= limit && (current / limit) * 100}>{current}</BalanceValue>
                  <BalanceCounter>
                    <BalanceLimit>0</BalanceLimit>
                    <BalanceLimit>{limit}</BalanceLimit>
                  </BalanceCounter>
                </BalanceProgress>
              </BalanceWrapper> */}
            </>
          ) : (
            <LoadingBalance />
          )}

          <DisconnectButtonWrapper>
            <DisconnectButton onClick={() => logout({ authContext, history })}>
              {t('disconnect', { firstUpperCase: true })}
              <i className='material-icons'>exit_to_app</i>
            </DisconnectButton>
          </DisconnectButtonWrapper>
        </DropdownContent>
      </DropdownWrapper>
    </HeaderWrapper>
  )
}

const LoadingBalance = () => {
  return (
    <Shimmer>
      <div style={{ width: '100%', height: 10, marginBottom: 10 }} />
      <div style={{ width: '100%', height: 10, marginBottom: 20 }} />
      <div style={{ width: '100%', height: 40 }} />
    </Shimmer>
  )
}

export default withRouter(HeaderMobile)

import React, { useContext, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import en from '../assets/images/flags/en.png'
import fr from '../assets/images/flags/fr.png'
import pt from '../assets/images/flags/pt.png'
import ro from '../assets/images/flags/ro.png'
import { AuthContext } from '../modules/auth/AuthContext'
import { HomeContext } from '../modules/home/HomeProvider'
import { changeLanguage } from './TranslationsActions'
import { TranslationsContext } from './TranslationsProvider'

const Wrapper = styled.div`
    width:50px;
    height:50px;
    position:relative;
    text-align: center;
`
const SelectWrapper = styled.div`
  ${props => props.mobile && `transform:translate3d(50px, -50px, 0);
  `}
  position:absolute;
  background-color:white;
  border-radius:4px;
  box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.1);
  z-index:333;
`
const Option = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  width:50px;
  height:35px;
  border-radius:5px;
  overflow:hidden;
  #lang-wrapper > &:first-of-type {
    height: 28px;
  }
  ${({ selected }) => selected && `
    justify-content:flex-start;
    align-items:flex-start;
    margin-top:5px;
  `}
  img {
    border-radius:4px;
  ${({ selected }) => selected && `
    width:50px;
    height:24px;    
  `}
    width:44px;
    height:24px;
  }
`
const getFlag = (langcode) => {
  if (!langcode) {
    return
  }
  switch (langcode.toLowerCase()) {
    case 'ro':
      return ro
    case 'pt':
      return pt
    case 'en':
      return en
    case 'fr':
      return fr
    default:
      return en
  }
}

function useOutsideAlerter(ref, closeSelect) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      closeSelect()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}
export default function () {
  const wrapperRef = useRef(null)
  const homeContext = useContext(HomeContext)
  const authContext = useContext(AuthContext)
  const translationsContext = useContext(TranslationsContext)
  const { language } = translationsContext
  const { t } = translationsContext
  const [isSelectOpened, setIsSelectOpened] = useState(false)
  useOutsideAlerter(wrapperRef, () => { setIsSelectOpened(false) })
  const changeCurrentLanguage = (language) => {
    changeLanguage({ translationsContext, imei: homeContext.state.terminal.imei, language, authContext })
    setIsSelectOpened(false)
  }
  const { languages } = homeContext.state
  return (
    <Wrapper id='lang-wrapper' onClick={() => { !isSelectOpened && setIsSelectOpened(true) }} ref={wrapperRef}>
      {isMobile && <h6 style={{fontSize: 10, fontWeight: 'normal', color: '#BBBECD', margin: 0}}>{t('language')}</h6>}
      <Option><img src={getFlag(language)} /></Option>
      {!isMobile && <h6 style={{fontSize: 10, fontWeight: 'normal', color: '#868DAA', margin: 0}}>{t('language')}</h6>}

      {
        isSelectOpened && languages && languages.length > 0 &&
        (
          <SelectWrapper>
            {languages.map(item =>
              (
                <Option onClick={() => { changeCurrentLanguage(item) }} key={item}><img src={getFlag(item)} /></Option>

              ))}
          </SelectWrapper>
        )
      }

    </Wrapper>

  )
}

import React from 'react'
import axios from 'axios'
import Router from './router/Router'
import './App.css'
import { AuthContextProvider } from './modules/auth/AuthContext'
import { API_URL } from './api/config'
import { TranslationsContextProvider } from './translations/TranslationsProvider'
axios.defaults.baseURL = API_URL
function App () {
  return (
    <AuthContextProvider>
      <TranslationsContextProvider>
        <Router />
      </TranslationsContextProvider>
    </AuthContextProvider>

  )
}

export default App

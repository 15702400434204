import React from 'react'

import {
  FETCH_VENDORS,
  FETCH_VENDORS_SUCCESS,
  SEARCH_FOR_CUSTOMER_AT_VENDOR,
  SEARCH_FOR_CUSTOMER_AT_VENDOR_FAIL,
  SEARCH_FOR_CUSTOMER_AT_VENDOR_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAIL,
  FINISH_TRANSACTION_FAIL,
  FINISH_TRANSACTION,
  FINISH_TRANSACTION_SUCCESS,
  FETCH_CUSTOMER_SUCCESS, SEARCH_WITH_PIN, SEARCH_WITH_PIN_SUCCESS, SEARCH_WITH_PIN_FAIL, REMOVE_PIN_ERROR
} from '../TransactionActions'
import { SET_DEPOSIT_FIELD } from './DepositActions'
import { FETCH_META_DATA_SUCCESS, FETCH_META_DATA, FETCH_META_DATA_FAIL } from '../../home/HomeActions'

const initialState = {
  loading: false,
  vendors: [],
  errorSearchForCustomer: '',
  loadingSearchForCustomer: false,
  customer: {},
  vendor: {},
  currencies: [],
  depositFields: {
    amlValidation: false,
    image: null,
    exchangedAmount: null,
    amount: '',
    customer: null,
    vendor: null,
    customerCurrency: null,
    selectedCurrency: null,
    selectedProduct: null
  },
  transactionError: '',
  loadingSendTransaction: false,
  successfulTransaction: null,
  loadingData: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case FINISH_TRANSACTION:
      return { ...state, loadingSendTransaction: true, successfulTransaction: null }
    case FINISH_TRANSACTION_FAIL:
      return {
        ...state,
        transactionError: action.payload,
        loadingSendTransaction: false,
        successfulTransaction: null
      }
    case FINISH_TRANSACTION_SUCCESS:
      return { ...state, loadingSendTransaction: false, successfulTransaction: action.payload }
    case SET_DEPOSIT_FIELD:
      return { ...state, depositFields: { ...state.depositFields, [action.payload.field]: action.payload.value } }
    case FETCH_META_DATA:
      return { ...state, loadingData: true }
    case FETCH_META_DATA_SUCCESS:
      if (action.payload.currencies && action.payload.currencies.length === 1) {
        return {
          ...state,
          currencies: action.payload.currencies,
          depositFields: {
            ...state.depositFields,
            selectedCurrency: { label: action.payload.currencies[0], value: action.payload.currencies[0] }
          },
          loadingData: false
        }
      } else {
        return { ...state, currencies: action.payload.currencies }
      }
    case FETCH_COMPANY_SUCCESS:
      if (action.payload.products && action.payload.products.length === 1) {
        return { ...state, [action.companyType]: action.payload, depositFields: { ...state.depositFields, selectedProduct: { value: action.payload.products[0].id, label: action.payload.products[0].name } } }
      }
      return { ...state, [action.companyType]: action.payload }
    case FETCH_VENDORS:
      return {
        loading: true
      }
    case SEARCH_FOR_CUSTOMER_AT_VENDOR:
      return { ...state, loadingSearchForCustomer: true, errorSearchForCustomer: '' }
    case FETCH_CUSTOMER_SUCCESS:
    case SEARCH_FOR_CUSTOMER_AT_VENDOR_SUCCESS:
      console.log(action.payload, 'search for user 1122a')
      return { ...state, customer: action.payload, errorSearchForCustomer: '', loadingSearchForCustomer: false, depositFields: { ...initialState.depositFields, customerCurrency: { value: action.payload.currency, label: action.payload.currency } } }
    case FETCH_COMPANY_FAIL:
    case SEARCH_FOR_CUSTOMER_AT_VENDOR_FAIL:
      return { ...state, errorSearchForCustomer: action.payload, loadingSearchForCustomer: false }
    case FETCH_VENDORS_SUCCESS:
      return {
        loading: false,
        vendors: action.payload.companies
      }
    case SEARCH_WITH_PIN: {
      return {
        ...state,
        loading: true
      }
    }
    case SEARCH_WITH_PIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        depositFields: { ...initialState.depositFields },
        loading: false
      }
    }
    case SEARCH_WITH_PIN_FAIL: {
      return {
        ...state,
        loading: false,
        transactionError: action.payload
      }
    }
    case REMOVE_PIN_ERROR: {
      return {...state, pinSearchError: ''}
    }
    default:
      return state
  }
}

const DepositContext = React.createContext()

function DepositContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <DepositContext.Provider value={{ state, dispatch }}>
      {children}
    </DepositContext.Provider>
  )
}

const DepositContextConsumer = DepositContext.Consumer

export { DepositContext, DepositContextConsumer, DepositContextProvider }
